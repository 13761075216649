import type {
  ComponentPropsWithoutRef,
  ElementType,
  PropsWithChildren,
} from 'react';
import clsx from 'clsx';

export type TitleProps<C extends ElementType = 'h1'> = {
  className?: string;
  as?: C;
} & ComponentPropsWithoutRef<C> &
  PropsWithChildren;

export function Title<C extends ElementType = 'h1'>({
  as,
  className,
  ...props
}: TitleProps<C>) {
  const As = as ?? 'h1';
  const classes = clsx(
    'font-poppins text-title sm:text-title-sm font-light mb-1',
    className,
  );
  return <As className={classes} {...props} />;
}
